import React from 'react'
import './hero.css'
import heroPic from '../assets/card-hero.jpg';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div>

    <div className='wrapper'>
      <img src={heroPic} alt="" className='pic'/>
    </div>

      <div className="cta-container">
          <h1 className="cta-h1">A Holistic Approach to Your Payment Processing</h1>
          <Link to="/Contact"><button className="cta-btn">
              Get in touch with us now!
          </button></Link>
      </div>
      </div>
  )
}

export default Hero