import {BrowserRouter,Routes, Route} from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Partners from './pages/Partners';
import Testimonials from './pages/Testimonials';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Blog from './pages/Blog';
import CreateBlog from './pages/CreateBlog';
import Header from './components/Header';
import Hero from "./components/Hero";
import Register from './pages/Register';
import './index.css'

function App() {
  return (
    <div
      className="bg"
    >
      <BrowserRouter>
        <Header />
        <Hero />
        <Routes>
          <Route path="/Home" exact element={<Home />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/About" exact element={<About />} />
          <Route path="/Contact" exact element={<Contact />} />
          <Route path="/Partners" exact element={<Partners />} />
          <Route path="/testimonials" exact element={<Testimonials/>} />
          <Route path="/Login8998h9ef44ed" exact element={<Login />} />
          <Route path="/Logout" exact element={<Logout />} />
          <Route path="/Blog" exact element={<Blog />} />
          <Route path="/createblog" exact element={<CreateBlog />} />
          <Route path="/Register8deca687cd" exact element={<Register />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
