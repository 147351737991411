import React from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './rform.css'


const RegisterForm = () => {
    const submitForm = async data =>{
        var res = await axios.post('https://sqmerchantservices.com:8443/userRegister',data)
        if(res.status === 200){
            reset();
        }
    }
    const { register, handleSubmit, reset,formState: { errors }, } = useForm({});
  return (
  <div className="regForm">
    <Form className="regForm" onSubmit={handleSubmit(submitForm)}>
    <Form.Group className="mb-3" controlId="formBasicUsername">
      <Form.Label>Username</Form.Label>
      <Form.Control type="text" placeholder="Enter Username"{...register('username')} />
      <Form.Text className="text-muted">
        We'll never share your username with anyone else.
      </Form.Text>
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Email</Form.Label>
      <Form.Control type="email" placeholder="Email" {...register('email')}/>
      <Form.Text className="text-muted">
        We'll never share your email with anyone else.
      </Form.Text>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <Form.Control type="password" placeholder="Password" {...register('password')}/>
    </Form.Group>

    <Button variant="primary" type="submit">
      Submit
    </Button>
  </Form>
  </div>
  )
}

export default RegisterForm