import React from 'react'
import body1 from '../assets/body-trusting-img.jpg';
import Phones from '../assets/empty-phones.jpg';
import Partners from '../assets/partners.jpg';
import { Link } from 'react-router-dom';
import {Container, Row, Col, Card, Carousel} from 'react-bootstrap';
import './home.css';
import EdgePayment from "../components/edgePayments.jsx"


function Home() {


  return (
    <div>
  <EdgePayment />
  <Carousel className='carousel' fade>
  <Carousel.Item interval={2500}>
  <Row>
  <p className='integrity-text'>Integrity</p>
  </Row>
  <Row className="text">
  <p>
  The Payments industry changes constantly. That is why we take a different
  approach. We walk you through any contracts step by step to ensure that you
  understand every detail and there will be NO HIDDEN FEES. We give our Client’s
  access to multiple processing options, ensuring that processors must compete
  for our Client’s business.
</p>
</Row>
    <Carousel.Caption>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={2500}>
  <Row><p className='security-text'>Security</p>
</Row>
  <Row className='text'><p>
  60 percent of small companies go out of business within six months of falling
  victim to a data breach or cyber-attack. With both the financial security and
  future of your business on the line, it is crucial for organizations of all
  sizes to have measures in place to monitor suspicious network activity, It is
  for this reason an absolutely critical part of your business that our staff of
  IT security experts conduct a cyber security threat assessment and help you
  repair vulnerabilities before there is data breach.
</p>
</Row>
    <Carousel.Caption>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={2500}>
  <Row>
 <p className='transparency-text'>
  Transparency
</p>

    </Row><Row className='text'>
<p>
  At SQ Merchant Service you are in great hands. We pride ourselves in bringing
  the best service for payment processing to our customers. We work with
  multiple platforms to get you the processing you need at the rates you want.
  We have been known to consistently beat the rates of Square, Shopify, Etsy,
  and many other revenue based processors. We work with authnet, payanywhere,
  1st Data, Verifone, Clover, and NMI where we have our own processing backend
  to help you with any needs you have.
</p>

</Row>

    <Carousel.Caption>

    </Carousel.Caption>
  </Carousel.Item>
</Carousel>

      <Container>
      <Row sm={1} md={3} className='cards'>
        <Col sm={1}>
        <Card className="card1">
          <Card.Img variant="top" src={body1} alt="Card Image" className='card1-img' />
          <Card.Body>
            <Card.Title>Community, Honesty & Integrity</Card.Title>
            <Card.Text>
              These are not just "Punch Lines" to Us, at
              SQ Merchant Services we pride ourselves on a strong community reputation.
            </Card.Text>
            <Link to='/Testimonials'><button className='home-button'>See our Testimonials
            </button></Link>
          </Card.Body>
        </Card>
        </Col>
        
        <Col sm={1}>
          <Card className='card3'>
  <Card.Img className="card3-img" variant='top' src={Partners} alt='Card Image' />
  <Card.Body>
    <Card.Title>Our partners and products</Card.Title>
    <Card.Text>
      Check out the many people we partner with and the products we can offer. From online payment to in person remote systems, we can help you.
    </Card.Text>
    <Link to='/Partners'><button className='home-button'>See our products</button></Link>
  </Card.Body>
</Card>

        </Col>
        <Col sm={1}>
        <Card className='card2'>
          <Card.Img variant='top' src={Phones} alt='Card Image' className="card2-img" />
          <Card.Body>
            <Card.Title className="text-center">DON'T WAIT</Card.Title>
            <Card.Text className="text-center">
              On hold with your providers anymore reach out to us and we can help you get out of that contract and into a better service with lower rates.
            </Card.Text>
            <Link to='/Contact'><button className='home-button'>
              Contact us now!
            </button></Link>
          </Card.Body>
        </Card>
        </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home