import React from 'react'
import Steve from '../assets/steve_headshot.png';
import James from '../assets/james-headshot.png';
import { Row, Col, Card } from 'react-bootstrap';
import './about.css'; 

const About = () => {
  return (
    <div>
      <Row sm={1} md={1} className='abt-body'>
        <Col sm={1} className='card-obj'>
        <Card className='abt-cards'>
          <Card.Body>
            <Card.Img src={Steve} alt="card image" className='card-img'/>
            <Card.Title className="title">Steven Quick CEO</Card.Title>
            <Card.Text> Although I was born in Abilene—the geographical heart of Texas—I grew up in Portland, where I learned about the heart of this green city by living in many of its charming suburbs. The ethos of these two great cities taught me that I could infuse my business with my ethics (my heart) and succeed.<br/> 

              As a small business owner in the 1990s, I leased my first mobile payment system.  Later, I became a salesperson who helped small business owners select the best credit/debit card payment system for their business.<br/>   

              It was as a salesperson for different payment systems vendor that I encountered the seedy side of the industry and resolved to build my own business based on ethics and honesty.<br/><br/>  

              In 2007, I jumped at the opportunity to start an ethical credit card processing/eCommerce consultation practice working with small business owners to save them money, gray hair, and heartaches.<br/>  

              What gets me up in the morning is that I have another opportunity to come alongside small business owners, show them that I take their businesses just as seriously as I do my own, and take the worry out of credit card processing off their already overloaded plates.<br/>  

              During this process from filing the paperwork until the first deposit, I provide the best of both worlds: the business owner can sign up with a national processor with lower fees and get personalized, local customer care from me!<br/>
              In addition to my own business, I mentor small business owners through:<br/>
            <ul>
              <li>SCORE (webinar presenter and mentor)</li>
              <li>Business Recovery Center (webinar presenter and mentor).</li>
              <li>Named Better Business Bureau’s Ethical Business of the Year Award, 2018</li>
              <li>BBB finalist 2017-2018</li>
              <li>President of the West Linn Chamber of Commerce </li>
            </ul>
            Member of the  Aloha Business Association 
            Member Of Chambers of Commerce in:
            <ul> 
            <li>Washington County</li>
            <li>Forest Grove</li>
            <li>Canby</li> 
            <li>Lake Oswego</li> 
            <li>Tigard</li> 
            <li>Tualatin</li>
            <li>West Linn</li> 
            <li>Wilsonville</li>
            <li>Sherwood</li>
            <li>Oregon City</li>
            </ul>
            Personal notes: 
            Active fundraiser for My Father’s House and JK25 Foundation.  </Card.Text>
          </Card.Body>
        </Card>
        </Col>
        <Col sm={1} className="card-obj">
        <Card className='abt-cards'>
          <Card.Body>
            <Card.Img src={James} alt="card image" className='card-img' />
            <Card.Title className="title">James Beers CTO</Card.Title>
            <Card.Text>In the Old West, the good guys wore white hats, In the New West of today, they still do.<br/>

                In a classic Western, when the new cowboy moseyed into town, you knew in a split second if he was a good guy like John Wayne, Jimmy Stewart, and Ronald Reagan because he was wearing a white hat. When our hero rode into a forlorn, dusty frontier town, he had the perspicuity to arrest the bad guys robbing the stage out on the highway or right the wrongs done by the black hatted bad guys to the good citizens of Yuma, Dodge, or Tombstone.<br/>  

                Today, the black hatted malcontents are roaming the information superhighway, looking to rob bank accounts, set up the roadblocks of Denial of Service, and wreck cybercrime havoc on the good citizens of Portland, San Diego, Austin, Boston, or anywhere in between.<br/>  

                Luckily, there is still a good guy in a white hat…and we hired him at SQ Merchant Services. And we are proud to have James Beers on our team!<br/><br/> 

                Growing up in Alaska and Oregon, James nurtured his avocation for computers into his vocation in computers. After 17 years of self-education in technology and programming,he went to DeVry where he obtained his associates in computer science. He has become an active member of the cybersecurity community he has a twitter and a following of hackers and developers, he has all the skills to build and secure your website or software.  </Card.Text>
          </Card.Body>
        </Card>
        </Col>
      </Row>
    </div>
  )
}

export default About