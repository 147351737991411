import React from 'react'
import RForm from '../components/RegisterForm'


const Register = () => {
  return (
    <div>
        <RForm />
    </div>
  )
}

export default Register