import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          SQMS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Edge processing</h4>
        <p>
          Use our edge processing offer and pass the tax to your total price. To inquire about this type of processing there is an edge processing option in the contact form. For instant signup goto the form <a href="https://apply.paymentshub.com/?source=52574.2"><Button>take me to the form</Button></a> 
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function EdgePayment() {
  const [modalShow, setModalShow] = React.useState(true);

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default EdgePayment;