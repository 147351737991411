import React from 'react';
import { Row, Carousel, Card, ListGroup } from 'react-bootstrap';
import './partners.css'
import PaFamily from '../assets/pay-anywhere-family.png';
import Verifone from '../assets/VX805.png';

const Partners = () => {
  return (
    <div>
      <Row className='part-cont-2'>
        <h2 className="part-cont-header"> Our products</h2>
        <Carousel controls={false} className='part-car'>
          <Carousel.Item className='part-car-item'>
            <Card style={{width: "90%"}} className='part-cards'>
              <Card.Img src={PaFamily} alt='card image' className='card-1-img'/>
              <Card.Body>
                <Card.Title>Pay Anywhere</Card.Title>
                <Card.Text>
                  This is the Pay Anywhere family that allows you do things such as:
                </Card.Text>
              </Card.Body>
              <ListGroup variant='flush'>
                <ListGroup.Item>Counter-Top payment acceptance</ListGroup.Item>
                <ListGroup.Item>Hand-Held payment acceptance</ListGroup.Item>
                <ListGroup.Item>Sim Card payment options</ListGroup.Item>
              </ListGroup>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card style={{ width: '90%' }} className='part-cards'>
            <Card.Img src={Verifone} alt='card image' className='card-2-img' />
            <Card.Body>
              <Card.Title>Verifone</Card.Title>
              <Card.Text>
                This is the Verifone family that allows you do things such as:
              </Card.Text>
            </Card.Body>
            <ListGroup variant='flush'>
              <ListGroup.Item>Counter-Top payment acceptance</ListGroup.Item>
              <ListGroup.Item>Hand-Held payment acceptance</ListGroup.Item>
              <ListGroup.Item>Great for convenience stores </ListGroup.Item>
            </ListGroup>
            </Card>
          </Carousel.Item>
        </Carousel>
      </Row>
    </div>
  )
}

export default Partners