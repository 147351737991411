import React from 'react'
import { Row, Card, Col } from 'react-bootstrap';
import './testimonials.css';


const Testimonials = () => {
  return (
    <div className='test-cont'>
      <Row className='test-1-row'>
        <Col>
          <Card style={{width:'100%'}} className='test-card'>
            <Card.Body>
              <Card.Title>Paul Vu</Card.Title>
              <Card.Subtitle>CEO, EDA Frames</Card.Subtitle>
              <Card.Text>I went through alot of processors and SQ Merchant Services helped even with my processor before them. If you have questions he is always there and fixes issues quickly for your peace of mind.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
  <Col>
    <Card style={{ width: '100%' }} className='test-card'>
      <Card.Body>
        <Card.Title>Marty Meisner</Card.Title>
        <Card.Subtitle>Kangen Water Store</Card.Subtitle>
        <Card.Text>Steven Quick and SQ Merchant Services provided us with all of the tools we needed for our business. We have memberships and retail, with all those different things he was able to not only set us up but saved us money. Thank you Steven.</Card.Text>
      </Card.Body>
    </Card>
  </Col>
  <Col>
    <Card style={{ width: '100%' }} className='test-card'>
      <Card.Body>
        <Card.Title>Jon Bippes</Card.Title>
        <Card.Subtitle>First Citizens Bank</Card.Subtitle>
        <Card.Text>Sq Merchant Services is a company that is cost effective. Their staff is competent. They have great follow up and great transparency which is unheard of in the card processing service world.</Card.Text>
      </Card.Body>
    </Card>
  </Col>
</Row>

    </div>
  )
}

export default Testimonials