import React from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './lform.css'
import { useCookies } from 'react-cookie';

const LoginForm = () => {
    var [cookies,setCookie] = useCookies('token')
    const submitForm = async data =>{
        var res = await axios.post('https://sqmerchantservices.com:8443/login',data)
        if(res.status === 200){
            setCookie('token',res.data.token,{
                path:'/',
                maxAge: 30000,
                sameSite:'lax'
            })
            reset();
            window.location.replace('/CreateBlog')
        }
    }
    const { register, handleSubmit, reset,formState: { errors }, } = useForm({});
  return (
  <div className="logForm">
    <Form className="logForm" onSubmit={handleSubmit(submitForm)}>
    <Form.Group className="mb-3" controlId="formBasicUsername">
      <Form.Label>Username</Form.Label>
      <Form.Control type="text" placeholder="Enter Username"{...register('username')} />
      <Form.Text className="text-muted">
        We'll never share your username with anyone else.
      </Form.Text>
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <Form.Control type="password" placeholder="Password" {...register('password')}/>
    </Form.Group>

    <Button variant="primary" type="submit">
      Submit
    </Button>
  </Form>
</div>
    )}

export default LoginForm